import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {BsFillCameraFill, BsImage} from "react-icons/bs";
import {Button, Modal, Offcanvas} from "react-bootstrap";
import ConfirmImageModal from "./Modal/ConfirmImageModal";
import {toast} from "react-hot-toast";
import camera from "../../../img/svg/Camera-bro.svg";
import {dataURLtoFile, maskCpf} from "../../../helpers/Functions";
import {WorkerRegisterAddressComponent} from "./Steps/WorkerRegisterAddressComponent";
import {blankDocBase64} from "../../../img/docSteps/blank_doc_base64";
import {api} from "../../../services/api";
import {FaFingerprint} from "react-icons/fa";
import {LuCheckCircle} from "react-icons/lu";
import {RxCrossCircled} from "react-icons/rx";


export const DocsAttachmentsComponent = ({apiPath,sendOnlyMobile, refetchProfile, selfie, actionSheet, status, titleSelectedImage, onlyImage, title, subTitle, data, dropCardImage}) => {
	const [files, setFiles] = useState([]);
	const [selectedImageFile, setSelectedImageFile] = useState([]);

	const [showActionSheet, setShowActionSheet] = useState(false);
	const [openCamera, setOpenCamera] = useState(false);

	const [takeSelfieModal, setTakeSelfieModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [disableButtonModal, setDisableButtonModal] = useState(false);
	const [proofResidenceModal, setProofResidenceModal] = useState(false);
	const [editAddress, setEditAddress] = useState(false);
	const [check, setCheck] = useState(false);


	const handleClose = () => {
		setShowActionSheet(false);
	}
	const handleShow = () => {
		setShowActionSheet(true)
	};

	const onDrop = useCallback(acceptedFiles => {
		let aux = files;

		if (aux.length === 1) aux = []

		if(aux.length < 1) {
			acceptedFiles.forEach((file) => {
				const reader = new FileReader();
				let type = file.type.split('/')

				reader.onabort = () => console.log('file reading was aborted')
				reader.onerror = () => console.log('file reading has failed')
				reader.onload = () => {

					let binaryStr = {
						note: file.name,
						image: reader.result,
						extension: type[type.length - 1].toUpperCase(),
					}

					aux.push(binaryStr)
					setSelectedImageFile(file);
					if (proofResidenceModal) {
						setProofResidenceModal(false);
						check && setCheck(false)
					}
					setShowModal(true);
				};

				reader.readAsDataURL(file)
			})
			setShowActionSheet(false);
			setFiles(aux);
		}

	}, [])

	const {getRootProps, getInputProps, open, isDragActive, fileRejections,} = useDropzone(
		{
			onDrop,
			accept: onlyImage ? {'image/png': [],'image/jpg': [],'image/jpeg': []} : {'image/png': [],'image/jpg': [],'image/jpeg': [],'application/pdf': []},
			noClick: (actionSheet || false),
			maxFiles: 1,
			multiple: false,
			noDrag: sendOnlyMobile
		})

	const checkPermissionCamera = () => {
		navigator.permissions.query({name: 'camera'}).then((permissionObj) => {
			if (permissionObj.state === 'granted') {
				setOpenCamera(true);
			};
		}).catch((error) => {
			setTakeSelfieModal(true);
		})
	}

	const handleTakePicture = (e) => {
		let input =  document.getElementById(`${apiPath}-input`)
		if (e === true)  {
			input.setAttribute('capture', true);
		} else if (input?.getAttribute('capture')) {
			document.getElementById(`${apiPath}-input`).removeAttribute('capture');
		}

		setShowActionSheet(false)
		// checkPermissionCamera()
		open()
	}

	const handleTakeSelfieModal = () => {
		setOpenCamera(true)
		setTakeSelfieModal(false)
	};

	const colorByStatus = () => {
		let color = ''
		switch (status) {
			case 'approval_queue':
				break
			case 'approved':
				color = '#44a71d'
				break
			case 'pending':
				// color = '#ff8b23'
				break
			case 'rejected':
				color = '#ad423c'
				break
		}

		return color
	}
	const handleSubmit = async (image, camera) => {
		setDisableButtonModal(true);
		const formData = new FormData();
		toast.loading('Enviando...', {id:1});
		if (camera) {
			formData.append(apiPath, dataURLtoFile(image, apiPath));
		} else {
			if (apiPath === 'proof-of-residence' && check) {
				formData.append(apiPath, dataURLtoFile(blankDocBase64, apiPath));
			} else {
				formData.append(apiPath, image);
			}
		}


		try {
			await api.post(`register/upload-docs/${apiPath}`, formData, {formData:true});
			await refetchProfile();
			setShowModal(false);
			setProofResidenceModal(false);
			toast.success('Documento enviado com sucesso!', {id:1});
		} catch (e) {
			toast.error(e || 'Parece que ocorreu um erro', {id:1})
		} finally {
			setDisableButtonModal(false);
		}
	}

	const handleClickCard = () => {
		if (window.innerWidth < 768) {
			if (actionSheet) {
				// setOpenCamera(true)
				// checkPermissionCamera()
				handleShow();
			} else {
				open();
			}
		} else if (!sendOnlyMobile) {
			open()
		}
	}

    return (
        <div>
            {(titleSelectedImage && (files.length > 0)) &&
                <h3 className={`text-primary text-center`}>{titleSelectedImage}</h3>
            }

            <div className={`card`}
                 style={{borderColor: colorByStatus(),}}
                 id={`${apiPath}-id`}
            >
                <input {...getInputProps(selfie && {capture: selfie})} id={`${apiPath}-input`}/>

                <div {...getRootProps()}
                     onClick={handleClickCard}
                     className={`card-body d-flex gap-2 gap-md-4 justify-content-center justify-content-md-start flex-wrap flex-md-nowrap shadow-hover ${sendOnlyMobile ? '' : 'cursor-pointer'}`}>
                    <div style={{maxWidth: 80}}>
                        <img src={dropCardImage}
                             className="w-100"
                             style={{objectFit: 'contain'}}
                             alt="pdf"
                        />
                    </div>
                    <div className={'text-center text-md-start'}>
                        <h5 className="fw-bold">
                            {(!isDragActive) ?
                                <span style={{color: colorByStatus()}}>{title}</span> :
                                <span className={'text-bg-success bg-opacity-10 text-success px-2 py-1 rounded-1'}>
									Solte aqui o arquivo
								</span>
                            }
                            {sendOnlyMobile &&
                                <span className={'d-none d-md-block fs-8 mt-1'} style={{color: '#ad423c'}}>(Necessário realizar através de um celular)</span>}
                        </h5>
                        <p style={{color: colorByStatus()}}
                           className={`${apiPath === 'proof-of-residence' ? 'mb-0' : ''}`}>
                            {subTitle}
                        </p>
                    </div>
                </div>
                <div className={`card-footer border-0 p-0  bg-white rounded-2`}>
                    <div className="col-12 col-md-6">
                        {apiPath === 'proof-of-residence' &&
                            <>
                                <div
                                    className={'d-flex  justify-content-center justify-content-md-start px-3 pb-md-2 pb-5'}>
                                    <button
                                        className="btn btn-outline-danger  d-flex flex-center gap-2 text-decoration-none"
                                        onClick={() => setProofResidenceModal(!proofResidenceModal)}>
                                        <i className="ki-duotone ki-file-deleted fs-4">
                                            <i className="path1"></i>
                                            <i className="path2"></i>
                                        </i>
                                        Não possuo comprovante
                                    </button>
                                </div>
                                <Modal show={proofResidenceModal} size={editAddress && "lg"}
                                       onHide={() => setProofResidenceModal(!proofResidenceModal)}>
                                    <Modal.Header closeButton>
                                        <h5 className={'mb-0'}>
                                            {editAddress ?
                                                <span className={'da-flex gap-2'}>
										<button className={'btn-reset back-button-step py-2 px-2 da-flex gap-2'}
                                                onClick={() => setEditAddress(false)}>
											<i className="ki-duotone ki-arrow-left fs-4">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
										</button>

										Edite seu endereço
									</span> :
                                                <>A informação será enviada para revisão</>
                                            }
                                        </h5>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {editAddress ?
                                            <>
                                                <WorkerRegisterAddressComponent activeStep={'CHANGE_ADDRESS'}
                                                                                isEdit={true}
                                                                                user={data}
                                                                                hideHeader={true}
                                                                                onSaveData={() => setEditAddress(!editAddress)}
                                                />
                                            </>
                                            :
                                            (data?.addresses.length > 0 ?
                                                    <div>
                                                        <div style={{margin: '0 -1rem'}}
                                                             className={'text-bg-primary bg-opacity-10 text-primary py-2'}>
                                                            <p className={'mb-0 fw-bold text-center'}>Comprovante de
                                                                residência</p>
                                                        </div>
                                                        <div className={'card mt-3'}>
                                                            <button
                                                                className="w-100 p-0 btn btn-link text-decoration-none shadow-hover"
                                                                onClick={() => setEditAddress(true)}>
                                                                <div className="card-body">
                                                                    <div className={'row g-md-2 text-start'}>
                                                                        <div className={'col-12 col-md-6'}>
															<span className={'text-gray-700'}>
																Rua:
															</span>
                                                                            <p className={'text-gray-800 mb-0 fw-bold'}>
                                                                                {data?.addresses[0].street_name}
                                                                            </p>
                                                                        </div>
                                                                        <div className={'col-12 col-md-6'}>
															<span className={'text-gray-700'}>
																Bairro:
															</span>
                                                                            <p className={'text-gray-800 mb-0 fw-bold'}>
                                                                                {data?.addresses[0].district}
                                                                            </p>
                                                                        </div>
                                                                        <div className={'col-12 col-md-6'}>
															<span className={'text-gray-700'}>
																Número:
															</span>
                                                                            <p className={'text-gray-800 mb-0 fw-bold'}>
                                                                                {data.addresses[0].street_number}
                                                                            </p>
                                                                        </div>

                                                                        <div className={'col-12 col-md-6'}>
															<span className={'text-gray-700'}>
																Cep:
															</span>
                                                                            <p className={'text-gray-800 mb-0 fw-bold'}>
                                                                                {data.addresses[0].cep}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={'pe-2 pb-2 fs-7'}>
                                                                    <p className={'mb-0 d-flex justify-content-end align-items-center gap-2'}>
                                                                        <i className="ki-duotone ki-notepad-edit fs-4">
                                                                            <i className="path1"></i>
                                                                            <i className="path2"></i>
                                                                        </i>
                                                                        Editar endereço
                                                                    </p>
                                                                </div>
                                                            </button>

                                                            <div
                                                                className={'border-top bg-gray-200 p-2 p-md-3 rounded-bottom'}>
                                                                <div className="form-check m-0">
                                                                    <input className="form-check-input cursor-pointer"
                                                                           checked={check}
                                                                           type="checkbox"
                                                                           onChange={() => setCheck(!check)}
                                                                           value=""
                                                                           id="proof_residence_check"
                                                                    />
                                                                    <label
                                                                        className="form-check-label cursor-pointer text-justify fs-7"
                                                                        htmlFor="proof_residence_check">
                                                                        Eu, <strong>{data.profile.first_name + ' ' + data.profile.last_name}</strong>,
                                                                        CPF
                                                                        nº <strong>{maskCpf(data.profile.cpf)}</strong>,
                                                                        na falta de
                                                                        documentos para comprovação de residência, em
                                                                        conformidade com o disposto na Lei
                                                                        7.115, de 29 de agosto de 1983, DECLARO para os
                                                                        devidos fins, sob penas da Lei,
                                                                        ser
                                                                        residente, domiciliado ou ofereço meus
                                                                        serviços/produtos no endereço acima.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : <></>
                                            )
                                        }
                                    </Modal.Body>
                                    {!editAddress &&
                                        <Modal.Footer>
                                            <Button onClick={open}
                                                    style={{flex: '0 1 25%'}}
                                                    disabled={disableButtonModal}
                                                    variant={'outline-dark'}
                                                    className={'d-flex flex-center gap-2'}>
                                                Trocar
                                                <i className="ki-duotone ki-arrows-circle fs-5">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>
                                            </Button>
                                            <Button onClick={() => handleSubmit(selectedImageFile)}
                                                    style={{flex: '0 1 50%'}}
                                                    className={'d-flex flex-center gap-2'}
                                                    disabled={(apiPath === 'proof-of-residence' && !disableButtonModal) ? !check : disableButtonModal}
                                            >
                                                {disableButtonModal ?
                                                    <>
                                                        <span
                                                            className="spinner-border spinner-border-sm text-ibrami-primary"
                                                            role="status"></span>
                                                        Aguarde...
                                                    </>
                                                    :
                                                    <>
                                                        Enviar
                                                        <i className="ki-duotone ki-send fs-5">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </>
                                                }
                                            </Button>
                                        </Modal.Footer>
                                    }
                                </Modal>
                            </>
                        }
                    </div>
                    <div className="col-12 col-md-6">
                        <div className={'position-absolute fs-7 fw-bold'} style={{right: 10, bottom: 5}}>
                            {status === 'approval_queue' &&
                                <span className={'d-flex flex-center gap-2'} style={{color: '#005c9f'}}>
									<FaFingerprint className={"fs-6"}/>
									Em análise
								</span>
                            }
                            {status === 'approved' &&
                                <span className={'d-flex flex-center gap-2'} style={{color: '#44a71d'}}>
									<LuCheckCircle  className={"fs-6"}/>
									Aprovado
								</span>
                            }
                            {(!status || status === 'pending') &&
                                <span style={{color: '#ff8b23'}}>Pendente</span>
                            }
                            {status === 'rejected' &&
                                <span className={'d-flex flex-center gap-2'} style={{color: '#ad423c'}}>
									<RxCrossCircled  className={"fs-6"}/>
									Rejeitado
								</span>
                            }
                        </div>
                    </div>
                </div>
            </div>


            {fileRejections.map(({file}, index) => {
                let type = file.path.split('.')
                type = type[type.length - 1]

                return (
                    <div className='text-center mt-2' key={index}>
						<span className='file-denied px-2 py-1  rounded-1'>
							O tipo de arquivo <span className={'fw-bold text-uppercase'}>{type}</span> não é permitido
						</span>
                    </div>
                )
            })}

            {(showModal && files.length > 0) ?
                <ConfirmImageModal show={showModal}
                                   onClose={() => setShowModal(false)}
                                   preview={files[0]}
                                   documentTitle={title}
                                   changeImage={open}
                                   isPdf={files[0].extension === "PDF"}
                                   disableButton={disableButtonModal}
                                   confirmImage={() => handleSubmit(selectedImageFile)}

                /> : <></>
            }

            <Modal show={takeSelfieModal} onHide={() => setTakeSelfieModal(!takeSelfieModal)} centered>
                <Modal.Body>
                    <div className={`text-center`}>
                        <div className={`d-flex justify-content-center `}>
                            <h3 className={`text-primary text-bg-primary bg-opacity-10 fw-bolder px-2 py-1 rounded-2 mb-0`}>
                                Permissão da câmera necessária!
                            </h3>
                        </div>

                        <img src={camera} style={{maxWidth: 350}} className={` rounded-2 my-4 camera-image`} alt=""/>
                        <div>
                            <h5 className={`text-gray-800`}>
                                A seguir precisamos que você clique em <span
                                className={`bg-opacity-10 text-secondary text-bg-secondary px-2 rounded-2`}>"permitir"</span> para
                                tirar sua selfie!
                            </h5>
                        </div>
                        <button onClick={handleTakeSelfieModal} className={`btn btn-secondary p-2 w-100 mt-3`}>
                            Tirar Selfie
                        </button>
                    </div>
                </Modal.Body>
            </Modal>


            {/*{openCamera &&*/}
            {/*	ReactDOM.createPortal(<WebCamComponent handleConfirm={(e) => handleSubmit(e, true)}*/}
            {/*										   selfie={true}*/}
            {/*										   closeCamera={() => setOpenCamera(false)}*/}
            {/*	/>, document.querySelector('body'))*/}
            {/*}*/}

            <Offcanvas show={showActionSheet} onHide={handleClose} placement={"bottom"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className={`text-gray-600`}>Selecione uma opção:</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={`d-flex flex-column gap-3`}>
                    <div className={`d-flex element-focus p-2 rounded-2`} onClick={handleTakePicture}>
                        <button className={`flex-grow-1 btn-reset d-flex align-items-center gap-2`}>
                            <BsImage size={24} className={`text-gray-600`}/> <span className={`fs-5 text-gray-600`}>Selecionar da galeria</span>
                        </button>
                    </div>

                    <div className={`d-flex element-focus p-2 rounded-2`} onClick={() => handleTakePicture(true)}>
                        <button className={`btn-reset d-flex align-items-center gap-2`}>
                            <BsFillCameraFill size={24} className={`text-gray-600`}/> <span
                            className={`fs-5 text-gray-600`}>Tirar uma foto</span>
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}
